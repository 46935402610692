import {
  hnhAccommodation, bankTicketingSystem, militaryRobot,
  ebenezer, esmond, japheth,
  stockpilot,
  gabrielDTH, godfredDTH,
  vision, mission,
} from './images/assets';
import { faLinkedin, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export const cardData = [
  {
    imageSrc: hnhAccommodation,
    title: 'Domi Tech Hub',
    description: 'Domi Tech Hub is a vibrant space dedicated to fostering innovation, collaboration, and growth.',
    viewProjectLink: 'https://example.com/view-project',
    ctaText: 'disabled',
    LearnMoreLink: 'https://example.com/learn-more',
    ctaText2: 'disabled',
  },
  {
    imageSrc: stockpilot,
    title: 'StockPilot IMS',
    description: 'StockPilot IMS is the ultimate solution for streamlining your inventory processes. ' +
      'StockPilot offers intuitive tools to ensure efficient stock control, ordering, and tracking.',
    viewProjectLink: 'https://stockpilot-aivp.onrender.com/',
    ctaText: 'View Project',
    LearnMoreLink: 'https://drive.google.com/file/d/1qLLgHjMH8i0z1-_UsSL-TjQTwcprnpSN/view?usp=sharing',
    ctaText2: 'Learn More',
  },
  {
    imageSrc: bankTicketingSystem,
    title: 'Freelance Services',
    description: 'Whether you need a website, a mobile app, or web application, we can desigin and build.',
    viewProjectLink: 'https://example.com/view-project',
    ctaText: 'disabled',
    LearnMoreLink: 'https://example.com/learn-more',
    ctaText2: 'disabled',
  },
];

export const SOP = {
  mission: {
    icon: mission,
    title: 'Our Mission',
    text: [`We develop Afrocentric technological solutions that interface the global
          stage with the African continent to do business in the most prominent industries,
          especially Agriculture, Commerce & renewable energy.`,
      `It is our aim to build an innovation
          and impact driven team of excellence and to nurture the next generation of tech savvy talent
          for the world from Africa. Our technologies empower our clients to be effective
          in their business and obtain measurable results that ensure business
          exposure, growth and profitability for both of us.`]
  },

  vision: {
    icon: vision,
    title: 'Our Vision',
    text: ['We aspire to be the centre of technology to the world in Africa\'s stronghold industries.',]
  }
}

export const contactData = [
  {
    icon: faWhatsapp,
    link: 'https://chat.whatsapp.com/J6fNqf7sAOOGvHrZ0x6sbw'
  },
  {
    icon: faLinkedin,
    link: 'https://www.linkedin.com/company/domitech-hub'
  },
  {
    icon: faInstagramSquare,
    link: 'https://instagram.com/domitech_hub'
  },
];

export const teamData = [
  {
    image: ebenezer,
    name: 'Ebenezer Tseh',
    title: 'CTO'
  },
  {
    image: japheth,
    name: 'Japheth Selorm',
    title: 'CEO'
  },
  {
    image: esmond,
    name: 'Esmond Adjei',
    title: 'COO'
  },
]

export const testimonialData = [
  {
    image: gabrielDTH,
    name: 'Gabriel Nii',
    beneficiary: 'DTH, Q4 2023',
    text: `Learning with DTH has been a life changing experience.
           With the skills I gained from DTH I have scaled from Zero to hero.
           I have from then built so many projects. Cheers to DTH🥂`
  },
  {
    image: godfredDTH,
    name: 'Godfred Annum Jr.',
    beneficiary: 'DTH, Q3 2024',
    text: `I came to Domitech Hub as a biology major with zero coding experience.
           Their incredible tutors made me realize programming isn't just for the tech-inclined.
           Bugs nearly drove me crazy, but with their help, 
           I built my first web app! If you doubt your ability to learn to code, 
           Domitech Hub will prove you wrong. They turned my expectations into something even more valuable.`
  },
  {
    image: militaryRobot,
    name: 'Jane Smith',
    beneficiary: 'DTH, Q2 2023',
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
  },
]