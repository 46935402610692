
// import styles
import './style/custom.css';
import './components/micro.css';

// import components
import Header from './containers/Header';
import Billboard from './containers/Billboard';
import MainContent from './containers/Content';
import Footer from './containers/Footer';


function App() {
  return (
    <div className="App">
      <Header />
      <Billboard />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
