import React from 'react';

// components
import Card from '../components/Card';
import Sqcard from '../components/squareCard';
import SlidingImage from '../components/slidingImages';
import BackToTopButton from '../components/backToTop';
import ProfileCard from "../components/profileCard";
import EmailForm from "../components/emailform";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import { check, doodle, africa } from '../images/assets';
// data
import { cardData, teamData, testimonialData, SOP } from '../content_data';


function MainContent() {

    return (
        <main className='main-content'>
{/*projects */}
            <section className='projects__section' id="projects" style={{backgroundImage: `url(${doodle})`}}>
                <div className='section__heading'>
                    <h3 className='section__title'>PROJECTS</h3>
                </div>
                <div className="hsc">
                {
                    cardData.map((card) =>
                        <Card
                            key={card.title}
                            imageSrc={card.imageSrc}
                            title={card.title}
                            description={card.description}
                            ctaText={card.ctaText}
                            viewProjectLink={card.viewProjectLink}
                            ctaText2={card.ctaText2}
                            LearnMoreLink={card.LearnMoreLink}
                        />
                    )}
                </div>
            </section>
{/* vision and mission */}
            <section className='projects__section'  id="vm" style={{backgroundImage: `url(${doodle})`}}>
                <div className='section__heading'>
                    <h3 className='section__title'>Vision & Mission</h3>
                </div>
                <div className='hsc'>
                    <Sqcard
                        cardImage={SOP.vision.icon}
                        title={SOP.vision.title}
                        text={SOP.vision.text.map( text => <p>{text}</p>)}
                    />
                    <Sqcard
                        cardImage={SOP.mission.icon}
                        title={SOP.mission.title}
                        text={SOP.mission.text.map( text => <p>{text}</p>)}
                    />
                </div>
            </section>
{/* team section */}
            <section className='team__section' id="team">
                <div className='section__heading'>
                    <h3 className='section__title'>The Team</h3>
                </div>
                <div className='hsc'>
                  {
                    teamData.map( member =>
                      <ProfileCard profileImage={member.image} name={member.name} title={member.title}/>
                    )
                  }
                </div>
            </section>
{/* why choose us */}
            <section className='advantage__section' >
                <div className='section__text'>
                    <h2>Why Choose US?</h2>
                    <p>We're more than just a choice. We're your partners in success</p>
                    <ul style={{listStyleImage: `url(${check})`}}>
                        <li>Reached <span className='highlight'>200+ </span> individuals</li>
                        <li>DTH has impacted <span className='highlight'>25+ </span>students</li>
                        <li><span  className='highlight'>3 </span>successful software products</li>
                    </ul>
                    <a href='#contact' className='btn'>Contact us</a>
                </div>
                <img src={africa} alt='africa'/>
            </section>
{/* endorsements section */}
            <section className='endorsement__section'>
                <div className='section__heading'>
                    <h3 className='section__title'>Testimonials</h3>
                </div>
                <SlidingImage data={testimonialData}/>
            </section>
{/* contact section */}
            <section className='contact__section' id='contact'>
                <EmailForm />
                <div className='section__text'>
                    <FontAwesomeIcon icon={faPhone} size='5x' bounce />
                    <h1>
                        Contact Us
                    </h1>
                    <p>
                        We are here to help answer any questions you may have about our services.
                        We look forward to hearing from you.
                    </p>
                </div>
            </section>
            <BackToTopButton />
        </main>
    );
};

export default MainContent;
